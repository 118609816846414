<template>
  <div>
    <banner-list
      :type="102"
      name="shop.storeHeaderBanner"
      :max="1"
      class="bannerlist"
    />
    <banner-list
      :type="101"
      name="shop.storeSwiperImages"
      :max="5"
      class="bannerlist"
    />
    <banner-list
      :type="103"
      name="shop.storeAboutImage"
      :max="1"
      class="bannerlist"
    />
  </div>
</template>
<script>
import BannerList from "@/components/BannerList";

export default {
  components: {
    BannerList,
  },
  data() {
    return {}
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.bannerlist {
  margin-bottom: 15px;
}
</style>