<template>
  <el-card class="page-card">
    <slot name="header" class="clearfix">
      <el-button type="primary" @click="add" v-if="max === 0 || count < max"
        >{{ $t("common.add") }} {{ $t(name) }}</el-button
      >
      <el-descriptions v-else :title="$t(name)"></el-descriptions>
    </slot>
    <sui-list
      ref="list"
      :column="tableColumn"
      :fun="tableFun"
      :req="tableFunReq"
      @edit="edit"
      @del="del"
      @hidden="hidden"
      @backflow="backflow"
    />
    <!-- <el-dialog
      :title="`${$t(formData.id ? 'common.edit' : 'common.add')} ${$t(name)}`"
      :visible="editing"
      width="500px"
      close-on-click-modal
      close-on-press-escape
      @close="close"
    > -->
    <el-drawer
      :title="`${$t(formData.id ? 'common.edit' : 'common.add')} ${$t(name)}`"
      :visible="editing"
      size="500px"
      close-on-press-escape
      @close="close"
    >
      <sui-form
        ref="form"
        :list="formList"
        :defaultdata="formData"
        @submit="submit"
      />
    </el-drawer>
    <!-- </el-dialog> -->
  </el-card>
</template>
<script>
import SuiForm from "@/components/s-ui/form";
import SuiList from "@/components/s-ui/list";
import { AddBanner, EditBanner, DelBanner, GetBannerList } from "@/utils/api";

const defaultFormData = {
  orderView: 1,
  isHidden: 0,
  jumpType: "1",
  jumpContent: "",
};
const jumpTypeRange = [
  {
    label: "banner.jump_type_0",
    value: "0",
  },
  {
    label: "banner.jump_type_url",
    value: "1",
  },
];
export default {
  props: {
    type: {
      type: Number,
      value: 0,
    },
    name: {
      type: String,
      value: "",
    },
    max: {
      type: Number,
      value: 0,
    },
  },
  components: { SuiForm, SuiList },
  data() {
    return {
      editing: false,
      formList: [
        {
          type: "input",
          name: "ID",
          hidden: true,
        },
        {
          type: "uploadimage",
          mode: "material",
          name: "image",
          label: "banner.image",
          rules: [
            {
              required: true,
              message: "banner.imageReq",
              trigger: "blur",
            },
          ],
        },
        {
          type: "number",
          name: "orderView",
          label: "banner.order_view",
          rules: [
            {
              required: true,
              message: "banner.order_viewReq",
              trigger: "blur",
            },
          ],
        },
        {
          type: "radio",
          name: "jumpType",
          label: "banner.jump_type",
          rules: [{ required: true }],
          range: jumpTypeRange,
          setHidden: ["jumpContent", "1", 0],
        },
        {
          type: "textarea",
          name: "jumpContent",
          label: "banner.jump_content",
          rules: [
            {
              required: true,
              message: "banner.jump_contentReq",
              trigger: "blur",
            },
          ],
        },
      ],
      formData: {},
      tableColumn: [
        // {
        //   name: "ID",
        //   label: "ID",
        // },
        {
          name: "orderView",
          label: "banner.order_view",
        },
        {
          type: "image",
          name: "image",
          label: "banner.image",
        },
        // {
        //   name: "type",
        //   label: "banner.type",
        // },
        {
          type: "switch",
          name: "isHidden",
          label: "banner.hidden",
        },
        {
          name: "jumpType",
          label: "banner.jump_type",
          range: jumpTypeRange.reduce(
            (a, v) => ({ ...a, [v.value]: v.label }),
            {}
          ),
        },
        {
          name: "jumpContent",
          label: "banner.jump_content",
        },
      ],
      tableFun: GetBannerList,
      tableFunReq: {
        type: this.type,
        pageSize: this.max || 10,
      },
      count: 0,
    };
  },
  mounted() {
    // console.log('this:', this);
  },
  methods: {
    add() {
      this.editing = true;
      const formData = {
        type: this.type,
        ...defaultFormData,
      };
      this.formData = formData;
      this.$nextTick(() => {
        this.formData = formData;
      });
    },
    edit(rowData) {
      console.log("edit-rowData:", rowData);
      // this.formData = rowData;
      this.editing = true;
      this.$nextTick(() => {
        // this.$refs["form"].setForm(rowData);
        this.formData = rowData;
      });
    },
    del(rowData) {
      console.log("del-rowData:", rowData);
      DelBanner({
        id: rowData.id,
      })
        .then((res) => {
          console.log("DelBanner-res:", res);
          this.$message({
            type: "success",
            message: this.$t("common.deleteSuccess"),
          });
          this.$refs["list"].getList();
        })
        .catch((err) => {
          console.log("DelBanner-err:", err);
        });
      // this.formData = rowData;
      // this.editing = true;
    },
    hidden(e, rowData, index) {
      console.log("hidden:", e, rowData, index);
      this.iEditBanner(rowData.id, { isHidden: e }).then(() => {
        this.$message({
          type: "success",
          message: this.$t("common.optSuccess"),
        });
        this.$refs["list"].getList();
      });
    },
    backflow(count) {
      this.count = count;
    },
    iEditBanner(id, params) {
      return EditBanner({
        id,
        ...params,
      });
    },
    submit(data) {
      console.log("submit:", data);
      const fn = data.id ? EditBanner : AddBanner;
      fn(data)
        .then((res) => {
          console.log("AddBanner-res:", res);
          this.$message({
            type: "success",
            message: this.$t("common.createSuccess"),
          });
          this.editing = false;
          this.$refs["list"].getList();
        })
        .catch((err) => {
          console.log("AddBanner-err:", err);
        });
    },
    close() {
      this.editing = false;
      this.formData = {};
      this.$refs["form"].resetFields();
    },
  },
};
</script>

<style lang="scss">
.hd {
  &-row {
    color: #333;
    font-weight: normal;
  }
}
.bd {
  &-row {
    text-align: center;
  }
}
page-card {
  .el-icon-upload {
    padding-top: 30px;
  }
}
</style>